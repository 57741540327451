$(document).ready(function () {
	//Hide element when smaller than 1025
	try {

		if ($(window).width() > 1024.1) {
			fullPageInit();
			aosInit();

		}
	} catch (error) {}
	setBackground();
	tabActive();
	swiperInit();
	toggleFunc();
	stickyNav();
	filterDisplay();
	backToTop();
	masonryInit();
});
window.addEventListener('load', AOS.refresh)
$("body").on("click", ".mobile-filter-toggle", function () {
	$(".product-filter-wrap").toggleClass("open-filter");
});
$("body").on("click", ".remove-filter", function () {
	$(".product-filter-wrap").removeClass("open-filter");
});
Fancybox.bind("[data-fancybox]", {
	parentEl: document.forms[0], // Element containing main structure
});

function masonryInit() {
	var $grid = $(".grid-masonry").masonry({
		itemSelector: ".grid-item",
	});
}
function stickyNav() {
	if ($(".sticky-nav").length) {
		$(".sticky-nav").scrollToFixed({
			zIndex: 99,
			marginTop: $("header").outerHeight(),
		});
	}

	$(window)
		.scroll(function () {
			var scrollDistance = $(window).scrollTop();
			// Assign active class to nav links while scolling
			$(".about-section-wrapper .about-section-id").each(function (i) {
				if (
					$(this).position().top -
						$(".sticky-nav").outerHeight() -
						$("header").outerHeight() -
						1 <=
					scrollDistance
				) {
					$(".sticky-nav li.active").removeClass("active");
					$(".sticky-nav li").eq(i).addClass("active");
				}
			});
		})
		.scroll();
	$(".sticky-nav a").on("click", function (event) {
		if (this.hash !== "") {
			let offset =
				$("header").outerHeight() + $(".sticky-nav").outerHeight();
			var hash = this.hash;
			$("html, body").animate(
				{
					scrollTop: $(hash).offset().top - offset,
				},
				800,
				function () {
					// window.location.hash = hash;
				}
			);
		} // End if
	});
}

function filterDisplay() {
	$(".filter-display-wrapper").each(function () {
		let x = 10;
		$(this).find(".toggle-item").slice(0, 10).show();
		let size = $(this).find(".toggle-item").length;
		if (size < x || size == x) {
			$(this).find(".ajax-btn-wrap").addClass("disble-btn");
		}
		$(".filter-display-wrapper")
			.find(".btn-secondary")
			.on("click", function (e) {
				e.preventDefault();
				x = x + 6;
				$(this)
					.parents(".filter-display-wrapper")
					.find(".toggle-item")
					.slice(0, x)
					.slideDown(function () {
						$(".grid-masonry").masonry({
							// options
							itemSelector: ".grid-item",
						});
					});
				if (x >= size || size < x) {
					$(this).parent().addClass("disble-btn");
				}
			});
	});
	$(".filter-masonry-wrapper").each(function () {
		let x = 6;
		$(this).find(".grid-item").slice(0, 6).show();
		let size = $(this).find(".grid-item").length;
		if (size < x || size == x) {
			$(this).find(".ajax-btn-wrap").addClass("disble-btn");
		}
		$(".filter-masonry-wrapper")
			.find(".btn-secondary")
			.on("click", function (e) {
				e.preventDefault();
				x = x + 6;
				$(this)
					.parents(".filter-masonry-wrapper")
					.find(".grid-item")
					.slice(0, x)
					.slideDown(function () {
						var $grid = $(".grid-masonry").masonry({
							itemSelector: ".grid-item",
						});
						$grid.masonry("reloadItems");
					});

				if (x >= size || size < x) {
					$(this).parent().addClass("disble-btn");
				}
			});
	});
	$(".filter-table-wrapper").each(function () {
		let x = 10;
		$(this).find("tbody tr").slice(0, 10).show();
		let size = $(this).find("tbody tr").length;
		if (size < x || size == x) {
			$(this).find(".ajax-btn-wrap").addClass("disble-btn");
		}
		$(".filter-table-wrapper")
			.find(".btn-secondary")
			.on("click", function (e) {
				e.preventDefault();
				x = x + 6;
				$(this)
					.parents(".filter-table-wrapper")
					.find("tbody tr")
					.slice(0, x)
					.slideDown();
				if (x >= size || size < x) {
					$(this).parent().addClass("disble-btn");
				}
			});
	});
}
function aosInit() {
	AOS.init({
		// Global settings:

		disable: window.innerWidth < 1024,
		startEvent: "load", // name of the event dispatched on the document, that AOS should initialize on
		initClassName: "aos-init", // class applied after initialization
		animatedClassName: "aos-animate", // class applied on animation
		useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
		disableMutationObserver: false, // disables automatic mutations' detections (advanced)
		debounceDelay: 20, // the delay on debounce used while resizing window (advanced)
		throttleDelay: 0, // the delay on throttle used while scrolling the page (advanced)
		// Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
		offset: 120, // offset (in px) from the original trigger point
		delay: 120, // values from 0 to 3000, with step 50ms
		duration: 500, // values from 0 to 3000, with step 50ms
		easing: "ease-in-out-back", // default easing for AOS animations
		once: true,
		mirror: true, // whether elements should animate out while scrolling past them
		anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
	});
}
function fullPageInit() {
	try {
		var myFullpage = new fullpage("#fullpage", {
			//Navigation
			bigSectionsDestination: null,
			menu: "#header,#menu-parallax,#fixed-side-nav",
			lockAnchors: false,
			fixedElements: "#header,#menu-parallax,#fixed-side-nav",
			anchors: [
				"firstPage",
				"secondPage",
				"thirdPage",
				"fourthPage",
				"fifthPage",
				"sixthPage",
				"seventhPage",
				"eigthPage",
				"ninePage",
				"tenthPage",
				"eleventhPage",
				"lastPage",
			],
			navigationTooltips: [
				"firstPage",
				"secondPage",
				"thirdPage",
				"fourthPage",
				"fifthPage",
				"sixthPage",
				"seventhPage",
				"eigthPage",
				"ninePage",
				"tenthPage",
				"eleventhPage",
				"lastPage",
			],
			slidesNavigation: true,
			slidesNavPosition: "bottom",
			showActiveTooltip: true,
			//Scrolling
			css3: true,
			offsetSections: true,
			scrollingSpeed: 700,
			autoScrolling: true,
			fitToSection: false,
			paddingTop: "100px",
			fitToSectionDelay: 600,
			scrollBar: false,
			easing: "easeInOutCubic",
			easingcss3: "ease",
			loopBottom: false,
			loopTop: false,
			loopHorizontal: true,
			continuousHorizontal: true,
			scrollHorizontally: true,
			continuousVertical: false,
			scrollOverflow: false,
			touchSensitivity: 15,
			normalScrollElementTouchThreshold: 5,
			//Accessibility
			keyboardScrolling: true,
			animateAnchor: true,
			recordHistory: true,
			//Design
			controlArrows: true,
			verticalCentered: true,
			resize: false,
			responsiveWidth: 0,
			responsiveHeight: 0,
			//Custom selectors
			sectionSelector: "section",
			dragAndMove: true,
			onLeave: function(){
				jQuery('section [data-aos]').removeClass("aos-animate");
			},
			onSlideLeave: function(){
				jQuery('section [data-aos]').removeClass("aos-animate");
			},
			afterSlideLoad: function(){
				jQuery('section.active [data-aos]').addClass("aos-animate");
			},
			afterLoad: function(){
				jQuery('section.active [data-aos]').addClass("aos-animate");
			   //jQuery('.fp-table.active .aos-init').addClass('aos-animate');
			}

			// afterLoad: function () {
			// 	$(".fp-table.active .aos-init").addClass("aos-animate");
			// },
			// beforeLeave : function () {
			// 	$(".fp-table.active .aos-init").addClass("aos-animate");
			// },
			// onLeave: function (origin, destination, direction) {
			// 	if (destination.isFirst) {
			// 		// $('.primary-banner video')[0].play();
			// 		$("header").removeClass("minimize");
			// 	} else {
			// 		$("header").addClass("minimize");
			// 	}
			// 	let section = $(".fp-section").eq(destination - 1);
			// 	$(section).find(".aos-init").removeClass("aos-animate");
			// },
		});
	} catch (error) {}
}

function backToTop() {
	try {
		if ($("body").hasClass("homepage")) {
			if ($(window).width() > 1024.1) {
				$(".back-to-top").on("click", function (e) {
					fullpage_api.moveTo(1);
				});
			}
			if ($(window).width() < 1024.01) {
				let btn = $(".back-to-top");
				btn.on("click", function (e) {
					e.preventDefault();
					$("html, body").animate(
						{
							scrollTop: 0,
						},
						"300"
					);
				});
			}
		} else {
			console.log("test");
			let btn = $(".back-to-top");
			btn.on("click", function (e) {
				e.preventDefault();
				$("html, body").animate(
					{
						scrollTop: 0,
					},
					"300"
				);
			});
		}
	} catch (error) {}
}
function toggleFunc() {
	var $hamburger = $(".hamburger");
	$(".main-menu-toggle").on("click", function () {
		$(".navbar-primary-menu").toggleClass("open-menu");
		$hamburger.toggleClass("is-active");
	});
	$(".side-nav .title em").on("click", function () {
		if ($(this).parents("li").hasClass("active")) {
			$(".side-nav .drop-menu").slideUp();
			$(".side-nav li").removeClass("active");
		} else {
			$(".side-nav .drop-menu").slideUp();
			$(".side-nav li").removeClass("active");
			$(this).parent().next().slideDown();
			$(this).parents("li").addClass("active");
		}
	});

	if ($(window).width() < 1025) {
		$(".drop-down .title em").on("click", function () {
			if ($(this).parents(".drop-down").hasClass("is-open")) {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
			} else {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
				$(this).parent().next().slideDown();
				$(this).parents(".drop-down").addClass("is-open");
			}
		});
	}

	$(".toggle-wrapper").each(function (index, element) {
		let $this = $(this);
		$this.addClass("toggle-instance-" + index);
		$(".toggle-instance-" + index + " .toggle-item .title").click(
			function () {
				if ($(this).parent().hasClass("is-toggle")) {
					$(this).parent().find(".article").slideUp();
					$(this).parent().removeClass("is-toggle");
				} else {
					$(this).parent().find(".article").slideDown();
					$(this).parent().addClass("is-toggle");
				}
			}
		);
	});
	$(".read-more-wrap").each(function (index) {
		$(this).addClass("read-instance-" + index);
		let $this = $(".read-instance-" + index);
		$this.each(function () {
			let height = $this.find(".txt-wrap").outerHeight();
			if (height < 220) {
				$this.find(".btn-read-more").addClass("hide");
			} else {
				$this.find(".txt-wrap").css({
					height: "220",
				});
			}
			let button = $this.find(".btn-read-more");
			let content = $this;
			button.on("click", function () {
				content.toggleClass("active");
			});
		});
	});
	if ($(window).width() < 1025) {
		$(".side-nav .text ").on("click", function () {
			$(this).next().slideToggle();
		});
	}
}

function tabActive() {
	$(".tab-nav li a").on("click", function () {
		$(this).parents(".tab-nav").find("li").removeClass("active");
		$(this).parents("li").addClass("active");

		var display = $(this).attr("data-type");
		$(this).parents("section").find(".tab-item").removeClass("active");
		$("#" + display).addClass("active");
	});
}

function swiperInit() {
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		speed: 1205,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},
		navigation: {
			nextEl: ".primary-banner .nav-arrow-next",
			prevEl: ".primary-banner .nav-arrow-prev",
		},
		pagination: {
			el: ".primary-banner  .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});

	var topBanner = new Swiper(".top-banner .swiper", {
		// Optional parameters
		speed: 1205,
		lazy: {
			loadPrevNext: true,
		},
		slidesPerView: 1,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
	});
	var projectSwiper = new Swiper(".project-detail-swiper .swiper", {
		// Optional parameters
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		loop: false,
		speed: 1000,
		breakpointsInverse: true,
		effect: "coverflow",
		grabCursor: true,
		centeredSlides: true,
		slidesPerView: "auto",

		breakpoints: {
			320: {
				slidesPerView: 1,
				coverflowEffect: {
					rotate: 1,
					depth: 60,
					stretch: 0,
					scale: 1,
					modifier: 3,
					slideShadows: true,
				},
			},
			1024: {
				coverflowEffect: {
					rotate: 1,
					depth: 60,
					stretch: 210,
					scale: 1,
					modifier: 3,
					slideShadows: true,
				},
			},
		},

		navigation: {
			nextEl: ".project-detail-swiper .nav-arrow-next",
			prevEl: ".project-detail-swiper .nav-arrow-prev",
		},
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
	});

	var otherProduct = new Swiper(".product-other-swiper .swiper", {
		// Optional parameters
		speed: 1205,
		spaceBetween: 30,
		lazy: {
			loadPrevNext: true,
		},
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 3,
			},
			1024: {
				slidesPerView: 4,
			},
			1280: {
				spaceBetween: 50,
				slidesPerView: 5,
			},
		},
		navigation: {
			nextEl: ".product-other-swiper .nav-arrow-next",
			prevEl: ".product-other-swiper .nav-arrow-prev",
		},
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
	});
	var otherSectorProduct = new Swiper(
		".product-other-sector-swiper .swiper",
		{
			// Optional parameters
			speed: 1205,
			spaceBetween: 30,
			lazy: {
				loadPrevNext: true,
			},
			breakpointsInverse: true,
			breakpoints: {
				320: {
					slidesPerView: 2,
				},
				576: {
					slidesPerView: 3,
				},
				768: {
					slidesPerView: 3,
				},
				1024: {
					slidesPerView: 4,
				},
				1280: {
					spaceBetween: 45,
					slidesPerView: 4,
				},
			},
			navigation: {
				nextEl: ".product-other-sector-swiper .nav-arrow-next",
				prevEl: ".product-other-sector-swiper .nav-arrow-prev",
			},
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
		}
	);
	var homePartner = new Swiper(".partner-swiper .swiper", {
		// Optional parameters
		speed: 1205,
		spaceBetween: 30,
		lazy: {
			loadPrevNext: true,
		},
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 3,
			},
			1280: {
				slidesPerView: 4,
			},
		},
		navigation: {
			nextEl: ".partner-swiper .nav-arrow-next",
			prevEl: ".partner-swiper .nav-arrow-prev",
		},
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
	});
	var certificateSwiper = new Swiper(".home-certificate-swiper .swiper", {
		// Optional parameters
		speed: 1205,
		spaceBetween: 30,
		lazy: {
			loadPrevNext: true,
		},
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 3,
			},
			1024: {
				slidesPerView: 4,
			},
			1280: {
				slidesPerView: 5,
			},
			1440: {
				slidesPerView: 6,
			},
		},
		navigation: {
			nextEl: ".home-certificate-swiper .nav-arrow-next",
			prevEl: ".home-certificate-swiper .nav-arrow-prev",
		},
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
	});

	var historyThumbs = new Swiper(".swiper-history-thumb  .swiper", {
		spaceBetween: 5,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			1280: {
				slidesPerView: 6,
			},
			1440: {
				slidesPerView: 8,
			},
		},

		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var historyMain = new Swiper(".swiper-history-main .swiper", {
		slidesPerView: 1,
		centeredSlides: true,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 3000,
		},
		speed: 750,
		loop: false,
		thumbs: {
			swiper: historyThumbs,
		},
		navigation: {
			nextEl: ".swiper-history-main .nav-arrow-next",
			prevEl: ".swiper-history-main .nav-arrow-prev",
		},
	});

	var productThumbs = new Swiper(".product-swiper-thumb  .swiper", {
		spaceBetween: 5,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 3,
			},
			576: {
				slidesPerView: 4,
			},
			1024: {
				slidesPerView: 5,
			},
			1280: {
				slidesPerView: 6,
			},
		},
		navigation: {
			nextEl: ".product-swiper-thumb .nav-arrow-next",
			prevEl: ".product-swiper-thumb .nav-arrow-prev",
		},
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var productMain = new Swiper(".product-swiper-main .swiper", {
		slidesPerView: 1,
		centeredSlides: true,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 3000,
		},
		speed: 750,
		loop: false,
		thumbs: {
			swiper: productThumbs,
		},
	});
	var homeAbout = new Swiper(".home-about-swiper .swiper", {
		slidesPerView: 1,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 3000,
		},
		breakpoints: {
			200: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
		},
		speed: 750,
		loop: false,
	});

	$(".single-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("single-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-single-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-single-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-instance-" + index);

		var swiper = new Swiper(".single-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			slidesPerView: 1,
			navigation: {
				nextEl: ".btn-next-single-" + index,
				prevEl: ".btn-prev-single-" + index,
			},
			pagination: {
				el: ".pagination-instance-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});
	$(".trinity-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("tri-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-tri-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-tri-" + index);

		var swiper = new Swiper(".tri-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: ".btn-next-tri-" + index,
				prevEl: ".btn-prev-tri-" + index,
			},
		});
	});
	$(".product-category-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("tri-ins-product" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-tri-pro-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-tri-pro-" + index);

		var swiper = new Swiper(".tri-ins-product" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
					spaceBetween: 30,
				},
				1440: {
					spaceBetween: 70,
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: ".btn-next-tri-pro-" + index,
				prevEl: ".btn-prev-tri-pro-" + index,
			},
		});
	});

	$(".four-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-four" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-four" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1280: {
					slidesPerView: 4,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
		});
	});
}

//Tab active

// Side
function setBackground() {
	$("[setBackground]").each(function () {
		var background = $(this).attr("setBackground");
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-size": "cover",
			"background-position": "center center",
		});
	});
	$("[setBackgroundRepeat]").each(function () {
		var background = $(this).attr("setBackgroundRepeat");
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-repeat": "repeat",
		});
	});
}

try {
	const counterUp = window.counterUp.default;

	const callback = (entries) => {
		entries.forEach((entry) => {
			const el = entry.target;
			if (entry.isIntersecting && !el.classList.contains("is-visible")) {
				counterUp(el, {
					duration: 2000,
					delay: 200,
				});
				el.classList.add("is-visible");
			}
		});
	};

	const IO = new IntersectionObserver(callback, { threshold: 1 });

	const el = document.querySelectorAll(".counter");

	el.forEach((counter) => {
		IO.observe(counter);
	});
} catch (error) {}
